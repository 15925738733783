import React from 'react';
import { useNetworkState, useUpdateEffect } from 'react-use';
import { Text } from '@chakra-ui/react';
import { toast } from 'react-toastify';

const OFFLINE_NOTIFICATION_KEY = 'offline-notification';

const NetworkDisconnectToast = () => {
  const networkState = useNetworkState();

  const closeOfflineNotification = () => {
    toast.dismiss(OFFLINE_NOTIFICATION_KEY);
  };

  useUpdateEffect(() => {
    if (networkState.online === false) {
      toast.error(<Text textAlign="center">Your Internet Disconnected</Text>, {
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: false,
        closeButton: false,
        draggable: false,
        toastId: OFFLINE_NOTIFICATION_KEY,
      });
    }

    return () => {
      if (networkState.online === false) {
        closeOfflineNotification();
      }
    };
  });

  return null;
};

export default NetworkDisconnectToast;
